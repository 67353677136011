<template>
    <v-card>
      <v-form
          class="pa-10"
          v-model="valid"
          ref="form"
          :lazy-validation="lazy"
          @submit.prevent="onForgotPassword"
      >
          <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
              <span class="headline">Forgot Password?</span>
          </v-card-title>
          <v-card-text>
              <v-text-field
                      label="Email"
                      v-model="email"
                      :rules="emailRules"
                      required
              ></v-text-field>
              <a
                  class="overline"
                  v-show="validationMessage"
                  target="_blank"
                  style="text-decoration: none;"
                  >{{ validationMessage }}
              </a>
          </v-card-text>

          <vue-recaptcha
                  ref="forgotPasswordRecaptcha"
                  @verify="recaptchaCallback"
                  @expired="expiredRecaptchaCallback"
                  size="invisible"
                  :sitekey="googleRecaptchaWebsiteKey">
          </vue-recaptcha>

          <v-btn block color="primary" type="submit" :loading="loading">Submit</v-btn>
      </v-form>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'
import { useRecaptchaStore } from '@/stores/recaptcha-store'
import { useLoginStore } from '@/stores/login-store'
import { Err } from '@/commons/result'

interface Data {
    valid: boolean,
    lazy: boolean,
    loading: boolean,
    emailRules: ((x: string) => boolean | string)[],
    email: string,
    validationMessage?: string
}

export default Vue.extend({
    name: 'GsForgotPasswordForm',

    components: {
        VueRecaptcha,
    },

    data: function (): Data {
        return {
            valid: true,
            lazy: true,
            loading: false,
            emailRules: [
                (v) => !!v || 'Email is required',
                (v) => /.+@.+\..+/.test(v) || 'Email must be valid',

            ],
            email: '',
            validationMessage: undefined
        }
    },

    methods: {
        async recaptchaCallback(token: string) {
            this.googleRecaptcha = token

            const result = await useLoginStore().forgotPasword({
                email: this.email,
                google_recaptcha: this.googleRecaptcha
            })

            this.loading = false
            useLoginStore().hideSignUpDialog()

            if(result instanceof Err) {
                this.resetForm()
            }

            this.loading = false
        },
        expiredRecaptchaCallback() {
            this.googleRecaptcha = undefined
        },
        onForgotPassword() {
            if(!(this.$refs.form as HTMLFormElement).validate()) {
                return
            }
            (this.$refs.forgotPasswordRecaptcha as HTMLFormElement).execute()
        },
        resetForm() {
            (this.$refs.forgotPasswordRecaptcha as VueRecaptcha).reset()
            this.googleRecaptcha = undefined
        },
    },

    computed: {
        googleRecaptchaWebsiteKey() {
            return process.env.VUE_APP_GOOGLE_RECAPTCHA_WEBSITE_KEY
        },
        googleRecaptcha: {
            get: function() {
                return useRecaptchaStore().forgotPasswordRecaptcha
            },
            set: function(token: string) {
                useRecaptchaStore().setForgotPasswordRecaptcha(token)
            },
        },
    }
})
</script>
