<template>
  <div>
    <router-view></router-view>
    <!-- Todo: Create toaster component -->
    <v-snackbar
      :value="showToast"
      v-for="(s, i) in toastMessages"
      :key="i"
      :style="{ 'margin-bottom': calcMargin(i) }"
      >{{ s }}</v-snackbar>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { useToastStore } from '@/stores/toast-store'
import { VSnackbar } from 'vuetify/lib'
import { RouterView } from 'vue-router'

export default Vue.extend({
  name: 'App',

  components: {
    VSnackbar,
    RouterView,
  },

  methods: {
    calcMargin(i: number) {
      return i * 60 + 'px'
    },
  },

  computed: {
    showToast() {
      return useToastStore().showToast
    },
    toastMessages() {
      return useToastStore().toastMessages
    }
  },
})
</script>
