import { defineStore } from 'pinia'

interface Recaptcha {
    signInRecaptcha?: string,
    signUpRecaptcha?: string,
    forgotPasswordRecaptcha?: string,
}

export const useRecaptchaStore = defineStore('recaptcha', {
  state: (): Recaptcha => {
    return {
      signInRecaptcha: undefined,
      signUpRecaptcha: undefined,
      forgotPasswordRecaptcha: undefined,
    }
  },
  actions: {
    setSignInRecaptcha(token: string) {
      this.signInRecaptcha = token
    },
    setSignUpRecaptcha(token: string) {
      this.signUpRecaptcha = token
    },
    setForgotPasswordRecaptcha(token: string) {
      this.forgotPasswordRecaptcha = token
    },
  },
})
