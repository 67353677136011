<template>
  <v-footer app absolute>
    <v-container fluid>
      <v-row no-gutters class="flex-md-row-reverse">
        <v-col sm="12" md="4" align-self="center" class="text-center text-md-right">
          <a href="https://www.bbb.org/us/ca/san-diego/profile/moving-services/goshare-inc-1126-172011908/#sealclick"
             class="bbb-link-img"
             target="_blank"
             rel="nofollow">
             <img src="https://seal-central-northern-western-arizona.bbb.org/seals/blue-seal-200-42-bbb-172011908.png"
                  alt="GoShare Inc BBB Business Review"></a>
        </v-col>
        <v-col sm="12" md="8" align-self="center">
          <v-row no-gutters justify="space-between" justify-md="start">
            <v-btn class="my-1" text href="/terms-of-service/">Terms of Service</v-btn>
            <v-btn class="my-1" text href="/privacy-policy/">Privacy Policy</v-btn>
            <v-btn class="my-1" text href="/goshare-reviews/">Reviews</v-btn>
            <v-btn class="my-1" text href="/trust/">Trust</v-btn>
            <v-btn class="my-1" text href="/help/">Help</v-btn>
            <v-btn class="my-1" text href="/contact-us/">Contact us</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'GsFooter',
})
</script>
