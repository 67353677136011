<template>
  <gs-login-layout>
    <v-main app>
      <v-container class="col col-xs-12 col-sm-8 col-md-6 col-lg-4 col-xl-3">
        <gs-sign-in-form
          @activate-forgot-password-form="showForgotPasswordForm = true"
          :fullscreen="$vuetify.breakpoint.xsOnly"
          ref="signInForm"
        ></gs-sign-in-form>

        <v-dialog
          :value="forgotPasswordDialog"
          @input="onCloseForgotPasswordDialog()"
          max-width="450px">
          <gs-forgot-password-form
            ref="forgotPasswordForm"
          ></gs-forgot-password-form>
        </v-dialog>

        <v-dialog
          :value="signUpDialog"
          @input="onCloseSignUpDialog()"
          max-width="450px">
          <gs-sign-up-form
            ref="signUpForm"
          ></gs-sign-up-form>
        </v-dialog>
      </v-container>
    </v-main>
  </gs-login-layout>
</template>

<script lang="ts">
import Vue from 'vue'
import GsLoginLayout from '@/layouts/LoginLayout.vue'
import GsSignInForm from './components/GsSignInForm.vue'
import GsSignUpForm from './components/GsSignUpForm.vue'
import GsForgotPasswordForm from './components/GsForgotPasswordForm.vue'
import { useLoginStore } from '@/stores/login-store'

interface Data {
    loading: boolean,
    showForgotPasswordForm: boolean,
    showResetPasswordForm: boolean,
}

export default Vue.extend({
  name: 'GsLoginView',

  data: (): Data => {
    return {
      loading: false,
      showForgotPasswordForm: false,
      showResetPasswordForm: false,
    }
  },

  components: {
    GsLoginLayout,
    GsSignInForm,
    GsSignUpForm,
    GsForgotPasswordForm
  },

  methods: {
    onCloseSignUpDialog() {
      const signUpForm = this.$refs.signUpForm as Vue & { resetForm: () => void }
      useLoginStore().hideSignUpDialog()
      signUpForm.resetForm()
    },
    onCloseForgotPasswordDialog() {
      const forgotPasswordForm = this.$refs.forgotPasswordForm as Vue & { resetForm: () => void }
      useLoginStore().hideForgotPasswordDialog()
      forgotPasswordForm.resetForm()
    },
  },

  computed: {
    signUpDialog() {
      return useLoginStore().signUpDialog
    },
    forgotPasswordDialog() {
      return useLoginStore().forgotPasswordDialog
    },
  },
})
</script>
