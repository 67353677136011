import { defineStore } from 'pinia'
import { SignInRequest,
  signInRequest,
  SignInResponse,
  SignUpRequest,
  SignUpResponse,
  signUpRequest,
  ForgotPasswordRequest,
  forgotPasswordRequest,
  ForgotPasswordResponse} from '@/requests/login-requests'
import { Err, Ok } from '@/commons/result'

interface LoginState {
    signUpDialog: boolean,
    forgotPasswordDialog: boolean,
}

export const useLoginStore = defineStore('login', {
  state: (): LoginState => {
    return {
        signUpDialog: false,
        forgotPasswordDialog: false,
    }
  },
  actions: {
    showSignUpDialog() {
      this.signUpDialog = true
    },
    hideSignUpDialog() {
      this.signUpDialog = false
    },
    showForgotPasswordDialog() {
      this.forgotPasswordDialog = true
    },
    hideForgotPasswordDialog() {
      this.forgotPasswordDialog = false
    },
    async signIn(request: SignInRequest): Promise<Ok<SignInResponse> | Err> {
      const result = await signInRequest(request)
      return result
    },
    async signUp(request: SignUpRequest): Promise<Ok<SignUpResponse> | Err> {
      const result = await signUpRequest(request)
      return result
    },
    async forgotPasword(request: ForgotPasswordRequest): Promise<Ok<ForgotPasswordResponse> | Err> {
      const result = await forgotPasswordRequest(request)
      return result
    }
  },
})
