import Vue from 'vue'
import * as VeeValidate from 'vee-validate'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import Toast from '@/plugins/toast'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueRouter from 'vue-router'
import LoginView from '@/views/login/LoginView.vue'
import {isAuthenticatedRequest} from './requests/login-requests'
import {Err} from './commons/result'

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(Toast)
Vue.use(VeeValidate)

VeeValidate.Validator.extend('phone_number_length', {
  validate: function(value) {
      return value.replaceAll(' ', '').length === 10
  },
  getMessage: () => 'The Mobile phone number field must have 10 digits.'
})

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/app/(.*)',
      redirect: '/trucks/login'
    },
    {
      name: 'Login',
      component: LoginView,
      path: '/trucks/login',
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/trucks/login') {
    const isAuthenticated = await isAuthenticatedRequest()
    const goToDashboard = () => {
      window.location.href = `https://${process.env.VUE_APP_DOMAIN_NAME}/trucks/dashboard`
    }
    isAuthenticated.andThen(goToDashboard)

    if(isAuthenticated instanceof Err) {
      next()
    }
  } else {
    next()
  }
})

new Vue({
  vuetify,
  render: (h) => h(App),
  pinia,
  router,
}).$mount('#app')
