<template>
  <v-card>
    <v-form
      class="pa-10"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
      @submit.prevent="onSignIn"
    >
      <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
        <span class="headline">Sign In</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Email"
          v-model="email"
          :rules="emailRules"
          required
        ></v-text-field>
        <v-text-field
          label="Password"
          v-model="password"
          :rules="passwordRules"
          type="password"
          required
        ></v-text-field>
        <a
          class="overline"
          @click="forgotPassword"
          target="_blank"
          style="text-decoration: none"
          >Forgot password?
        </a>
      </v-card-text>

      <vue-recaptcha
        class="login-recaptcha"
        ref="signInRecaptcha"
        @verify="recaptchaCallback"
        @expired="expiredRecaptchaCallback"
        size="invisible"
        :sitekey="googleRecaptchaWebsiteKey"
      >
      </vue-recaptcha>

      <v-btn
        block
        color="primary"
        type="submit"
        @submit="signIn"
        :loading="loading">
        Sign In</v-btn>

      <p class="text--secondary pt-4">
        <v-btn @click="showSignUpDialog()" text color="primary">
          Create a Customer account </v-btn
        ><br />
        <v-btn href="/trucks/delivery-professional/create-account" text color="primary">
          Create a Delivery Pro account</v-btn
        >
      </p>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import { useLoginStore } from '@/stores/login-store'
import { VueRecaptcha } from 'vue-recaptcha'
import { Ok, Err } from '@/commons/result'
import { useRecaptchaStore } from '@/stores/recaptcha-store'
import { SignInRequest, SignInResponse } from '@/requests/login-requests'

interface Data {
  loading: boolean,
  valid: boolean,
  lazy: boolean,
  emailRules: ((x: string) => boolean | string)[],
  email: string,
  password: string,
  passwordRules: ((x?: string) => boolean | string)[]
}

export default Vue.extend({
  name: 'GsSignInForm',

  props: ['userData'],

  components: { VueRecaptcha },

  data: (): Data => ({
    loading: false,
    valid: true,
    lazy: true,
    emailRules: [
      (v) => !!v || 'Email is required',
      (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
    ],
    email: '',
    password: '',
    passwordRules: [(v) => !!v || 'Password is required'],
  }),

  methods: {
    showSignUpDialog() {
      useLoginStore().showSignUpDialog()
    },
    async signIn(request: SignInRequest): Promise<Ok<SignInResponse> | Err> {
      return useLoginStore().signIn(request)
    },
    async recaptchaCallback(token: string) {
      this.googleRecaptcha = token

      const goToDashboard = () => window.location.href = `https://${process.env.VUE_APP_DOMAIN_NAME}/trucks/dashboard`

      const loginUser = await this.signIn({
        email: this.email,
        password: this.password,
        google_recaptcha: token,
        app_type: 'A',
        cameFromBooking: 1,
      })
      this.loading = false

      loginUser
        .andThen(goToDashboard)

      if(loginUser instanceof Err) {
        (this.$refs.signInRecaptcha as VueRecaptcha).reset()
      }
    },
    expiredRecaptchaCallback() {
        this.googleRecaptcha = undefined
    },
    onSignIn() {
      if ((this.$refs.form as HTMLFormElement).validate()) {
        this.loading = true;
        (this.$refs.signInRecaptcha as VueRecaptcha).execute()
      }
    },
    forgotPassword() {
      useLoginStore().showForgotPasswordDialog()
    },
  },

  computed: {
    googleRecaptchaWebsiteKey() {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_WEBSITE_KEY
    },
    googleRecaptcha: {
      get: function() {
        return useRecaptchaStore().signInRecaptcha
      },
      set: function(token: string) {
        useRecaptchaStore().setSignInRecaptcha(token)
      },
    },
  },
})
</script>

<style>
.login-recaptcha > .grecaptcha-badge {
  bottom: 40px !important;
  z-index: 999;
}
</style>
