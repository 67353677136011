//import { Result } from '@/commons/result'
import { Err, Ok } from '@/commons/result'
import { internalRequest } from '@/requests'

export interface SignInRequest {
  email: string,
  password: string,
  google_recaptcha: string,
  app_type: string,
  cameFromBooking: number
}

export interface SignInResponse {
  results: LoggedUser,
  message: string,
  status: boolean
}

export interface IsAuthenticatedResponse {
    status: boolean
}

interface Coupon {
    id: number,
    user_id: number,
    coupon: string,
    redemption_reward: number,
    category: number,
    branch_link: string
}

export interface LoggedUser {
    branch_link: string,
    contact_zoho_id?: string,
    coupon?: Coupon,
    driver_submerchant_account?: string,
    gift_card?: string,
    gift_card_balance?: number,
    user_email: string,
    user_first_name: string,
    user_id: number,
    user_last_name: string,
    user_log_in: string,
    user_mobile: string,
    user_photo?: string,
    user_rating?: string,
    user_state: string,
    user_status: string
}

export const signInRequest = async (signInRequest: SignInRequest): Promise<Ok<SignInResponse> | Err> => {
  const result = await internalRequest<SignInRequest, SignInResponse>({
    method: 'post',
    url: '/user/login_web',
    data: signInRequest,
  })

  return result
}

export const isAuthenticatedRequest = async (): Promise<Ok<IsAuthenticatedResponse> | Err> => {
    const result = await internalRequest<void, IsAuthenticatedResponse>({
        method: 'get',
        url: '/access-controls/is-authenticated',
        data: {},
    })

    return result
}

export interface SignUpRequest {
  first_name: string,
  last_name: string,
  business_name: string,
  email: string,
  password: string,
  phone_number: string,
  state: string,
  country: string,
  zipcode: string,
  city: string,
  source: string,
  google_recaptcha: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignUpResponse {
  // todo
}

export const signUpRequest = async (signUpRequest: SignUpRequest) => { // todo: formData type
  const result = await internalRequest<SignUpRequest, SignUpResponse>({
    method: 'POST',
    url: '/customer/register_customer_simple',
    data: signUpRequest,
  })

  return result
}

export interface ForgotPasswordRequest {
  email: string,
  google_recaptcha: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ForgotPasswordResponse {
  // todo
}

export const forgotPasswordRequest = async (forgotPasswordRequest: ForgotPasswordRequest) => {
  const result = await internalRequest<ForgotPasswordRequest, ForgotPasswordResponse>({
    method: 'POST',
    url: '/recover_password',
    data: forgotPasswordRequest,
  })

  return result
}

interface ZipCodeRequest {
  country: string,
  postal_code: string,
  origin: string
}

interface ZipCodeResponse {
  cities: string[],
  state: string,
  city?: string
}

export const getCityStateFromZipCodeRequest = async (data: ZipCodeRequest):
    Promise<Ok<ZipCodeResponse> | Err> => {
  const result = await internalRequest<ZipCodeRequest, ZipCodeResponse>({
    method: 'POST',
    url: '/geolocation/search-postal-code',
    data,
  })

  return result
}
