import axios, { AxiosRequestConfig, Method } from 'axios'
import { toast } from '@/plugins/toast'
import { ok, err, Ok, Err } from '@/commons/result'

interface Request<T> {
  method: Method, // todo: change to enum
  url: string,
  data: T | Record<string, never>,
  headers?: Record<string, string>
}

export const apiRequest = async <T, S> ({ method, url, data }: Request<T>): (Promise<Ok<S> | Err>) => {
  const slash = !url.startsWith('/') ? '/' : ''
  const apilUrl = `https://${process.env.VUE_APP_API_DOMAIN_NAME}${slash}${url}`

  const response = request<T, S>({
    method,
    url: apilUrl,
    data,
    headers: {'Content-Type': 'application/json'},
  })

  return response
}

// Todo: encapsulate response in Result.Success or Result.Error
export const internalRequest = async <T, S> ({ method, url, data }: Request<T>): (Promise<Ok<S> | Err>) => {
  const slash = !url.startsWith('/') ? '/' : ''
  const internalUrl = `https://${process.env.VUE_APP_DOMAIN_NAME}/trucks${slash}${url}`

  const formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value)
  }

  const response = request<FormData, S>({
    method,
    url: internalUrl,
    data: formData,
  })

  return response
}

const request = async <T, S>({ method, url, data }: Request<T>): (Promise<Ok<S> | Err>) => {
    const options: AxiosRequestConfig = {
      method: method,
      url,
      data,
      withCredentials: true,
    }

    try {
      const response = await axios(options)

      if (response.data.status) {
        if(response.data.message) {
          toast(response.data.message)
        }
        return ok(response.data)
      } else {
        const errorMessage = response.data.message
        if(errorMessage) toast(errorMessage)
        return err(errorMessage) // or it could be an error object.
      }
    } // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch(error: any) {
      return err(error.message)
    }
}
