export class Err {
    value: Error

    constructor(val: Error) {
      this.value = val
    }

    andThen(): Err {
        return this
    }

    chain(): Err {
        return this
    }

    flatten(): Err {
        return this
    }

    toString() {
      return `Err(${this.value})`
    }
}

export class Ok<S> {
    value: S

    constructor(value: S) {
        this.value = value
        return this
    }

    andThen<R>(f: (value: S) => R): Ok<R> {
        return new Ok(f(this.value))
    }

    // Use this function when f(x) already returns Ok or Err
    chain<R>(f: (value: S) => R): R {
        return f(this.value)
    }

    flatten(): Ok<S> | Err {
        if(this.value instanceof Err || this.value instanceof Ok) {
            return this.value
        }
        return this
    }

    toString() {
        return `Ok(${this.value})`
    }
}

export const err = function(value: Error): Err {
    return new Err(value)
}

export const ok = function<S>(value: S): Ok<S> {
    return new Ok(value)
}
