<template>
  <v-card>
    <validation-observer ref="form">
      <v-form @submit.prevent="onSignUp" class="pa-10">
        <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
          <span class="headline">Sign Up</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <gs-v-text-field
                name="first name"
                rules="required"
                v-model.trim="signUpData.firstName"
                label="First name"
              >
              </gs-v-text-field>
            </v-col>
            <v-col>
              <gs-v-text-field
                name="last name"
                rules="required"
                v-model.trim="signUpData.lastName"
                label="Last name"
              >
              </gs-v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <gs-v-text-field
                  name="business name"
                  label="Business Name"
                  v-model="signUpData.businessName"
                  :rules="businessNameValidationRules"
                  :disabled="userIsNotBusiness">
              </gs-v-text-field>
              <v-checkbox
                  class="mt-0"
                  v-model="userIsNotBusiness"
                  label="I don't have a business name"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Email"
                v-model="signUpData.email"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <gs-v-text-field
                name="password"
                vid="password"
                rules="required|min:8"
                label="Create password"
                type="password"
                v-model.trim="signUpData.password"
              >
              </gs-v-text-field>
            </v-col>
            <v-col>
              <gs-v-text-field
                name="password"
                rules="required|confirmed:password"
                label="Confirm password"
                type="password"
                v-model.trim="signUpData.passwordConfirmation"
              >
              </gs-v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <gs-v-text-field
                name="phone"
                rules="required|phone_number_length"
                label="Mobile phone number"
                v-model.trim="signUpData.phone"
                :mask="{ phone: true, phoneRegionCode: 'US' }"
              ></gs-v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider
                name="country"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :items="countries"
                  item-text="country"
                  item-value="abbr"
                  label="Country"
                  v-model="signUpData.country"
                  @change="resetAddress"
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
              {{ signUpData.country }}
            </v-col>
            <v-col>
              <gs-v-text-field
                name="zip code"
                rules="required|numeric|min:5"
                ref="userZipCode"
                label="Zip code"
                v-model.trim="signUpData.zipCode"
                @change="getCityState"
                :mask="{ numeric: true, blocks: [5] }"
              >
              </gs-v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                :items="cities"
                v-model="signUpData.city"
                :readonly="cities.length === 1"
                :loading="loadingCityAndState"
                label="City"
              ></v-select>
            </v-col>

            <v-col>
              <v-text-field
                :value="signUpData.state"
                label="State"
                readonly
                :loading="loadingCityAndState"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="googleRecaptchaWebsiteKey">
              <vue-recaptcha
                ref="signUpRecaptcha"
                @verify="recaptchaCallback"
                @expired="expiredRecaptchaCallback"
                size="invisible"
                :sitekey="googleRecaptchaWebsiteKey"
              >
              </vue-recaptcha>
            </v-col>
          </v-row>
          <v-btn block color="primary" type="submit" :loading="loading"
            >Sign Up</v-btn
          >
        </v-card-text>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script lang="ts">
import {ValidationObserver, ValidationObserverInstance, ValidationProvider} from 'vee-validate'
import {getCityStateFromZipCodeRequest} from '@/requests/login-requests'
import {useLoginStore} from '@/stores/login-store'
import GsVTextField from '@/components/GsVTextField.vue'
import countries, {Country} from '@/constants/countries'
import {VueRecaptcha} from 'vue-recaptcha'
import {useRecaptchaStore} from '@/stores/recaptcha-store'
import Vue from 'vue'
import {Err} from '@/commons/result'

interface SignUpData {
  firstName: string,
  lastName: string,
  businessName: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  phone: string,
  country: string,
  zipCode: string,
  city: string,
  state: string
}

interface Data {
  userConfirmPassword?: string,
  userIsNotBusiness: boolean,
  loading: boolean,
  countries: Country[],
  loadingCityAndState: boolean,
  emailRules: ((x: string) => boolean | string)[],
  cities: string[],
  signUpData: SignUpData
}

export default Vue.extend({
  name: 'GsSignUpForm',

  components: {
    GsVTextField,
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
  },

  data: function (): Data {
    return {
      userConfirmPassword: undefined,
      userIsNotBusiness: false,
      loading: false,
      countries,
      loadingCityAndState: false,
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
      cities: [],
      signUpData: {
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        phone: '',
        country: 'US',
        zipCode: '',
        city: '',
        state: ''
      }
    }
  },

  methods: {
    async recaptchaCallback(token: string) {
      this.googleRecaptcha = token

      const result = await useLoginStore().signUp({
        first_name: this.signUpData.firstName,
        last_name: this.signUpData.lastName,
        business_name: this.signUpData.businessName,
        email: this.signUpData.email,
        phone_number: this.signUpData.phone,
        password: this.signUpData.password,
        google_recaptcha: token,
        source: 'W',
        state: this.signUpData.state,
        city: this.signUpData.city,
        country: this.signUpData.country,
        zipcode: this.signUpData.zipCode,
      })

      const goToDashboard = () => setTimeout(() => {
        window.location.href = `https://${process.env.VUE_APP_DOMAIN_NAME}/trucks/dashboard`
      }, 1000)

      this.loading = false

      useLoginStore().hideSignUpDialog()

      result.andThen(goToDashboard)

      if(result instanceof Err) {
        this.resetForm()
      }

      this.loading = false
    },
    expiredRecaptchaCallback() {
        this.googleRecaptcha = undefined
    },
    onSignUp() {
      (this.$refs.form as ValidationObserverInstance).validate()
        .then((isValid: boolean) => {
          if(isValid) {
            this.loading = true
            ;(this.$refs.signUpRecaptcha as VueRecaptcha).execute()
          }
        })
    },
    resetAddress() {
      (this.$refs.userZipCode as HTMLFormElement).$refs.validationProvider.reset()
      (this.$refs.userZipCode as HTMLFormElement).$refs.validationProvider.reset()
      this.signUpData.zipCode = ''
      this.cities = []
      this.signUpData.state = ''
    },
    async getCityState() {
      if((this.$refs.userZipCode as HTMLFormElement).$refs.vTextField.validate()) {
        this.loadingCityAndState = true
        const response = await getCityStateFromZipCodeRequest({
          postal_code: this.signUpData.zipCode,
          country: this.signUpData.country,
          origin: 'gs-sign-up-form'
        })

        response.andThen((payload) => {
          this.cities = payload.cities
          this.signUpData.city = this.cities.length > 0 ? this.cities[0] : ''
          this.signUpData.state = payload.state
        })

        this.loadingCityAndState = false
      }
    },
    resetForm() {
      (this.$refs.signUpRecaptcha as VueRecaptcha).reset()
      this.googleRecaptcha = undefined
    },
  },

  computed: {
    googleRecaptchaWebsiteKey(): string {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_WEBSITE_KEY
    },
    googleRecaptcha: {
      get: function() {
        return useRecaptchaStore().signUpRecaptcha
      },
      set: function(token: string) {
        useRecaptchaStore().setSignUpRecaptcha(token)
      },
    },
    businessNameValidationRules(): string {
      return this.userIsNotBusiness ? '' : 'required'
    }
  },

  watch: {
    userIsNotBusiness(newVal) {
      if(newVal === true) {
        this.signUpData.businessName = ''
      }
    }
  }

})
</script>
