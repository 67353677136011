<template>
  <validation-provider
    :name="name"
    :rules="rules"
    :vid="vid"
    ref="validationProvider"
    v-slot="{ errors }"
  >
    <v-text-field
      ref="vTextField"
      v-model="innerValue"
      v-bind="$attrs"
      v-on="$listeners"
      :error-messages="errors"
    >
    </v-text-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Cleave from 'cleave.js'
require('cleave.js/dist/addons/cleave-phone.i18n.js')

export default {
  name: 'GsVTextField',
  components: {
    ValidationProvider,
  },
  props: {
    rules: String,
    name: String,
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
    mask: {
      type: Object,
      required: false,
      default: undefined,
    },
    unmaskValue: {
      type: Boolean,
      required: false,
      default: true,
    },
    vid: {
      type: String,
      required: false,
    },
  },
  data: function() {
    return {
      innerValue: this.value,
    }
  },
  watch: {
    value: function (newVal) {
      this.innerValue = newVal
    },
  },
  mounted: function () {
    if (this.mask) {
      const options = this.mask
      options.onValueChanged = this.onValueChanged.bind(this)
      this._vCleave = new Cleave(this.$refs.vTextField.$refs.input, options)
      this._vCleave.setRawValue(this.value.toString())
    }
  },
  methods: {
    onValueChanged: function({ target }) {
      if (this._vCleave) {
        this.$nextTick(function () {
          this.innerValue = target.value
          this.$emit(
            'input',
            this.unmaskValue ? target.rawValue : target.value
          )
        })
      }
    },
  },
  $_veeValidate: {
    value: function () {
      return this.mask
        ? this.unmaskValue
          ? this._vCleave.getRawValue()
          : this._vCleave.getFormattedValue()
        : this.innerValue
    },
  },
}
</script>
