import { useToastStore } from '@/stores/toast-store'

const toast = (message: string) => {
  console.debug(message)
  useToastStore().toast(message)
}

const Toast = {
  install(Vue: { prototype: { $toast: (message: string) => void } }) {
    Vue.prototype.$toast = function(message: string) {
      toast(message)
    }
  },
}

declare module 'vue/types/vue' {
  interface Vue {
    $toast: (message: string) => void
  }
}

export { Toast as default, toast }
