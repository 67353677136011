import { defineStore } from 'pinia'

interface Toaster {
    toastMessages: string[],
    showToast: boolean
}

export const useToastStore = defineStore('toast', {
  state: (): Toaster => {
    return {
        toastMessages: [],
        showToast: false,
    }
  },
  actions: {
    toast(message: string) {
      this.toastMessages.push(message)
      this.show()
    },
    show() {
      this.showToast = true
    }
  },
})
