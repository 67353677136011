<template>
  <v-app>
    <v-app-bar color="primary" dark app>
      <v-toolbar-title>
        <img
          src="https://static.goshare.co/logos/goshare_logo_white.svg"
          alt="GoShare"
          class="logo-img"
      /></v-toolbar-title>
      <div class="flex-grow-1"></div>

      <v-toolbar-items>
        <template>
          <v-btn text href="/trucks/order">Book now</v-btn>
        </template>
      </v-toolbar-items>
    </v-app-bar>

    <slot></slot>

    <gs-footer></gs-footer>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import GsFooter from './components/GsFooter.vue'

export default Vue.extend({
  name: 'GsLoginLayout',
  components: {
    GsFooter,
  },
})
</script>

<style>
.logo-img {
  margin: 12px 0;
  height: 40px;
}
</style>
